import {httpPost} from '@/plugins/request'

/**
 * 用户登录
 *
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiLogin(data = [])
{
    return httpPost('/manage/auth/login', data)
}

/**
 * 第三方登录
 *
 * @param platform
 * @param data
 * @returns {Promise<*>}
 */
export function apiSocialiteLogin(platform, data)
{
    return httpPost(`/manage/socialite/${platform}/login`, data)
}
