<template>
  <div style="width:480px;margin:0 auto;">
    <div id="ww_login"></div>
    <div style="text-align: center"><h4 v-text="text"></h4></div>
  </div>
</template>

<script>
import * as ww from '@wecom/jssdk'
import {getApiUrl} from "@/plugins/common"
import {apiSocialiteLogin} from '@/api/authApi'
import {loginUser} from "@/plugins/auth";
export default {
  mounted() {
    this.platform = this.$route.params.platform
    this.code = this.$route.query.code
    // this.createWeworkWebview()
    this.socialiteRedirect(this.platform)
  },
  data () {
    return {
      platform: '',
      code: '',
      ua: '',
      text: '',
    }
  },
  methods: {
    // 跳转第三方登录
    socialiteRedirect(platform) {
      if (this.code) {
        this.loginCodeUser()
      } else {
        this.ua = navigator.userAgent.toLowerCase()
        if (/wxwork/i.test(this.ua)) {
          window.location.href = getApiUrl(`/manage/socialite/${platform}/redirect`)
        } else {
          this.createWeworkWebview()
        }
      }
    },
    createWeworkWebview() {
      let platform = this.platform

      // 企业微信
      if (platform === 'wework') {
        const vue = this
        ww.createWWLoginPanel({
          el: '#ww_login',
          params: {
            login_type: 'CorpApp',
            appid: 'ww07f39146d675193a',
            agentid: '1000003',
            redirect_uri: window.location.href,
            state: 'loginState',
            redirect_type: 'callback',
          },
          onLoginSuccess({code}) {
            vue.loginCodeUser(code)
          },
          onLoginFail(err) {
            console.log(err)
          },
        })
      }
    },
    loginCodeUser (code = null) {
      // 请求 登录
      apiSocialiteLogin(this.platform, {code: (code ?? this.code)}).then(data => {
        loginUser(data)

        // 去重定向地址或主页
        if (this.$route.query.redirect_url) {
          window.location = this.$route.query.redirect_url
        } else {
          this.$router.push({name: 'index'})
        }
      })
    }
  }
}
</script>
